import {
  Tooltip, Pie, PieChart, Cell, ResponsiveContainer
} from 'recharts';
import { Box, Button } from '@mui/material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieChartDisplay = (chart, index, CustomTooltip, handlePageChange) => {
  if (!chart.data || chart.data.length === 0) {
    return <p>No data available</p>;
  }

  const chartData = chart.data.totals;
  let dataKey = "totals";
  let label = ({ location }) => `${location}`;

  // Ensure chartData is an array
  if (!Array.isArray(chartData)) {
    //console.error('Expected chartData to be an array but got:', chartData);
    return <p>Loading...</p>;
  }
  return (
    <ResponsiveContainer width={'100%'} height={500}>
      <PieChart style={{
        border: '1px solid black',
        borderRadius: '8px',
      }}>
        <Pie
          dataKey={dataKey}
          isAnimationActive={true}
          data={chartData}
          cx="50%"
          cy="50%"
          outerRadius="70%"
          fill="#8884d8"
          label={label}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
      {chart.filters.timelineType === 'annual' && (
        <Box>
          <Button disabled={chart.page === 1} onClick={() => handlePageChange(chart, -1, index)}>
            Previous
          </Button>
          <Button disabled={chart.page === chart.totalPages} onClick={() => handlePageChange(chart, 1, index)}>
            Next
          </Button>
        </Box>
      )}
    </ResponsiveContainer>
  );
};

export default PieChartDisplay;

