import {
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,  
  Customized,
  Rectangle, 
  ResponsiveContainer,
} from 'recharts';

const CustomizedRectangle = (props) => {
  const { formattedGraphicalItems } = props;
  const firstSeries = formattedGraphicalItems[0];
  const secondSeries = formattedGraphicalItems[1];

  if (!firstSeries || !secondSeries) return null;

  const comparisonKey = firstSeries.props.points[0].payload.location ? 'location' : 'cause';
  return (
    <>
      {firstSeries.props.points.map((firstSeriesPoint, index) => {
        const secondSeriesPoint = secondSeries.props.points.find(
          (point) => point.payload[comparisonKey] === firstSeriesPoint.payload[comparisonKey]
        ) || { x: firstSeriesPoint.x, y: 0 };
        // console.log(firstSeriesPoint)
        // console.log(secondSeriesPoint)

        const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;

        return (
          <Rectangle
            key={firstSeriesPoint.payload.location}
            width={10}
            height={Math.abs(yDifference)}
            x={secondSeriesPoint.x - 5}
            y={yDifference > 0 ? secondSeriesPoint.y : secondSeriesPoint.y + yDifference}
            fill={yDifference > 0 ? 'red' : 'green'}
          />
        );
      })}
    </>
  );
};

const CustomTick = ({ x, y, payload }) => {
  const wrappedText = payload.value.split(' ').map((word, index) => (
    <tspan key={index} x={x} dy={index === 0 ? 0 : 15}>
      {word}
    </tspan>
  ));
  return (
    <text x={x} y={y+60} textAnchor="middle">
      {wrappedText}
    </text>
  );
};

const LineChartDisplay = (chart, dataKeyMapping, CustomTooltip) => {

  const { xKey, yKey } = dataKeyMapping[chart.filters.timelineType];
  const comparisonData = chart.data.comparisonTotals ? chart.data.comparisonTotals : [];
  const chartData = chart.data.totals;
  const dataRate = (chartData || []).map(item => item[yKey] || 0);
  const compRate = (comparisonData || []).map(item => item[yKey] || 0);
  const maxDataValue = Math.max(...dataRate, ...compRate);

  return (
  <ResponsiveContainer width={'100%'} height={500}>
    <LineChart data={chartData}
      style={{
        border: '1px solid black',
        borderRadius: '8px',
      }}
      margin={{ top: 5, right: 30, left: 50, bottom: 70 }}
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} textAnchor="end" angle={30} tick={<CustomTick/>} />
      <YAxis domain={[0, maxDataValue]}/>
      <Tooltip content={<CustomTooltip />} />
      <Legend formatter={(value, entry) => {
            if (chart.filters.timelineType === 'custom') {
                if (entry.dataKey === yKey) return `${chart.filters.startPeriod} - ${chart.filters.endPeriod}`;
            } else {
                if (entry.dataKey === yKey) return `${chart.year}`;
                if (entry.dataKey === 'comparisonTotals' && comparisonData.length > 0) return `${chart.comparisonYear}`;
            }
            return value;
        }}
        layout="horizontal"
        verticalAlign="bottom"
        align="center"
        iconType="plainline"
        wrapperStyle={{ marginTop: '60px' }}
      />
      <Line type="monotone" dataKey={yKey} stroke="#8884d8" activeDot={{ r: 8 }} />
      {comparisonData.length > 0 && (
        <>
        <Line type="monotone" dataKey="comparisonTotals" data={comparisonData} stroke="#82ca9d" activeDot={{ r: 8 }} />
        <Customized component={CustomizedRectangle} />
        </>
      )}
    </LineChart>

  </ResponsiveContainer>
)}

export default LineChartDisplay;
