import { useState } from 'react';

const useChartData = () => {

  var filters = {
    timelineType: 'annual',
    startPeriod: '2023-04-14',
    endPeriod: '2023-04-15',
    // location: '',
    // source: '',
    // cause: '',
    // injured: '',
    // reference: '',
  };

  const [charts, setCharts] = useState([{
    data: [],
    filters: filters,
    type: 'line',
    year: new Date().getFullYear(),
    comparisonYear: '',
    page: 1,
    totalPages: 1,
    secondPage: 1,
    totalPeriodPages: 13,
    locations: [],
    sources: [],
    causes: [],
  }]);

  const [id, setIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(charts.map(() => ({
    annual: true,
    quarterly: false,
    '13periods': false,
    custom: false
  })));

  const [customDates, setCustomDates] = useState(charts.map(() => ({
    startPeriod: '',
    endPeriod: ''
  })));

  const [filterOptions, setFilterOptions] = useState({
    location: [],
    source: [],
    cause: [],
    injured: [],
    reference: [],
  });

  const [locations, setLocations] = useState([]);
  const [causes, setCauses] = useState([]);
  const [sources, setSources] = useState([]);

  const handlePageChange = async (chart, increment, index) => {
    const newPage = chart.page + increment;
    setCharts(currentCharts => currentCharts.map((currentChart, idx) => {
      if (currentChart === chart) {
        return { ...currentChart, page: newPage };
      }
      return currentChart;
    }));
    setIndex(index);
  };

  const handleSecondPageChange = (chart, direction, index) => {
    setCharts(prevCharts =>
      prevCharts.map((c, i) => {
        if (i === index) {
          const newPeriodPage = chart.secondPage + direction;
          return {
            ...chart,
            secondPage: newPeriodPage > 0 && newPeriodPage <= chart.totalPeriodPages ? newPeriodPage : chart.secondPage
          };
        }
        return c;
      })
    );
    setIndex(index);
  };

  const handleChangeChartType = (index, type) => {
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return { ...chart, type };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleComparisonYearChange = (index, event) => {
    const year = event.target.value;
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          comparisonYear: year
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleElementChange = async (index, element) => {
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          element: element
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleYearChange = async (index, event) => {
    const year = event.target.value;
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          year: year
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleChange = (index, event) => {
    setIsLoading(true);

    const newChecked = [...checked];
    // Reset all checkboxes
    newChecked[index] = {
      annual: false,
      quarterly: false,
      '13periods': false,
      custom: false
    };

    // Activate the clicked one
    newChecked[index][event.target.name] = event.target.checked;
    setChecked(newChecked);

    // Reset custom dates if not custom
    if (event.target.name !== 'custom') {
      const newDate = [...customDates];
      newDate[index] = { startDate: '', endDate: '' };
      setCustomDates(newDate);
      handleFilterChange(index, 'timelineType', event.target.name);

      setCharts(currentCharts => currentCharts.map((chart, idx) => {
        if (idx === index) {
          return { ...chart, page: 1, secondPage: 1 };
        }
        return chart;
      }));
    }
    setIsLoading(false);
  };

  const handleDateChange = (index, event) => {
    const newDate = [...customDates];
    const { name, value } = event.target;
    newDate[index][name] = value;
    setCustomDates(newDate);
  };

  const handleFilterChange = (chartIndex, filterName, value) => {
    setCharts(currentCharts => currentCharts.map((chart, index) => {
      if (index === chartIndex) {
        return {
          ...chart,
          filters: {
            ...chart.filters,
            [filterName]: value
          }
        };
      }
      return chart;
    }));
    setIndex(chartIndex);
  };

  const handleLocationChange = (index, newLocations) => {
    setCharts((prevCharts) =>
      prevCharts.map((chart, i) =>
        i === index ? { ...chart, locations: newLocations } : chart
      )
    );
    setIndex(index);
  };

  const handleSourceChange = (index, newSources) => {
    setCharts((prevCharts) =>
      prevCharts.map((chart, i) =>
        i === index ? { ...chart, sources: newSources } : chart
      )
    );
    setIndex(index);
  };

  const handleCauseChange = (index, newCauses) => {
    setCharts((prevCharts) =>
      prevCharts.map((chart, i) =>
        i === index ? { ...chart, causes: newCauses } : chart
      )
    );
    setIndex(index);
  };

  return {
    filters,
    id,
    setIndex,
    isLoading,
    setIsLoading,
    charts,
    setCharts,
    checked,
    setChecked,
    customDates,
    setCustomDates,
    filterOptions,
    setFilterOptions,
    locations,
    setLocations,
    causes,
    setCauses,
    sources,
    setSources,
    handleSourceChange,
    handlePageChange,
    handleSecondPageChange,
    handleChangeChartType,
    handleComparisonYearChange,
    handleElementChange,
    handleYearChange,
    handleChange,
    handleDateChange,
    handleLocationChange,
    handleCauseChange,
  };
};

export default useChartData;
