import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, BarChart, ResponsiveContainer
} from 'recharts';
import { Box, Button } from '@mui/material';

const BarChartDisplay = (chart, index, dataKeyMapping, CustomTooltip, handlePageChange) => {
  const { xKey, yKey } = dataKeyMapping[chart.filters.timelineType];
  const chartData = chart.data.totals;

  return (
    <ResponsiveContainer width={'100%'} height={500}>
      <BarChart width={500} height={300} data={chartData} style={{
        border: '1px solid black',
        borderRadius: '8px',
      }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xKey} />
        <YAxis />
        <Legend />
        <Bar dataKey={yKey} fill="#8884d8" />
        <Tooltip content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
} 

export default BarChartDisplay;
