import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

const ChartAutocomplete = ({values, selectedValues, handleValueChange, element}) => {
  return (
    <Autocomplete
      sx={{ width: 200 }}
      multiple
      id={`${element}-autocomplete`}
      options={values}
      getOptionLabel={(option) => option.value}
      onChange={handleValueChange}
      value={selectedValues}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} label={option.value} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label={element} variant="outlined" placeholder="choose one" />
      )}
    />
  );
};

export default ChartAutocomplete;
